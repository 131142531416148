import { useEffect } from 'react';
import anime from 'animejs/lib/anime.es.js';
import 'styles/illustrations/startups.css';
import { useOnScreen } from 'utils/hooks';

export default function StartupScene() {
  const [ref, visible] = useOnScreen();

  useEffect(() => {
    if (visible) animate();
  }, [visible]);

  return (
    <div className="startups-illustration" ref={ref}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 843 807"
      >
        <title>Startup</title>
        <defs>
          <linearGradient
            id="New_Gradient_Swatch_6"
            y1="695.52"
            x2="823.28"
            y2="695.52"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#203075" />
            <stop offset="1" stopColor="#0a1236" />
          </linearGradient>
          <linearGradient
            id="New_Gradient_Swatch_4"
            x1="346.84"
            y1="129.24"
            x2="461.19"
            y2="129.24"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#5ec2bb" />
            <stop offset="1" stopColor="#203075" />
          </linearGradient>
          <linearGradient
            id="New_Gradient_Swatch_2"
            x1="346.84"
            y1="268.67"
            x2="461.19"
            y2="268.67"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#203075" />
            <stop offset="1" stopColor="#ffe864" />
          </linearGradient>
          <linearGradient
            id="New_Gradient_Swatch_6-2"
            x1="288.5"
            y1="287.65"
            x2="352.39"
            y2="287.65"
            xlinkHref="#New_Gradient_Swatch_6"
          />
          <linearGradient
            id="linear-gradient"
            x1="512.9"
            y1="289.51"
            x2="449.04"
            y2="291.3"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#203075" />
            <stop offset="0.01" stopColor="#1f2e71" />
            <stop offset="0.1" stopColor="#17245b" />
            <stop offset="0.2" stopColor="#111c4a" />
            <stop offset="0.33" stopColor="#0d163f" />
            <stop offset="0.5" stopColor="#0b1338" />
            <stop offset="1" stopColor="#0a1236" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-2"
            x1="349.91"
            y1="72.59"
            x2="464.65"
            y2="72.59"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.07" stopColor="#203075" />
            <stop offset="0.71" stopColor="#ffe864" />
          </linearGradient>
          <linearGradient
            id="New_Gradient_Swatch_6-3"
            x1="-4863.28"
            y1="294.66"
            x2="-4799.39"
            y2="294.66"
            gradientTransform="matrix(-1, 0, 0, 1, -4366.39, 0)"
            xlinkHref="#New_Gradient_Swatch_6"
          />
          <linearGradient
            id="New_Gradient_Swatch_6-4"
            x1="310.07"
            y1="293.04"
            x2="373.95"
            y2="293.04"
            xlinkHref="#New_Gradient_Swatch_6"
          />
          <linearGradient
            id="New_Gradient_Swatch_6-5"
            x1="346.84"
            y1="176.01"
            x2="461.19"
            y2="176.01"
            xlinkHref="#New_Gradient_Swatch_6"
          />
          <linearGradient
            id="New_Gradient_Swatch_8"
            x1="175.97"
            y1="627.69"
            x2="428.86"
            y2="627.69"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#203075" />
            <stop offset="1" stopColor="#ffe964" />
          </linearGradient>
        </defs>

        <polygon
          id="base"
          className="startups-cls-1"
          points="449.39 806.59 823.28 706.4 371.42 584.46 0 686.1 449.39 806.59"
        />

        <g id="stars">
          <polygon
            className="startups-cls-2"
            points="706.87 229.78 716.65 254.39 740.43 263.87 722.4 276.62 725.47 301.75 704.55 285.03 682.66 291.07 687.77 267.99 671.17 246.6 695.25 249.05 706.87 229.78"
          />
          <polygon
            className="startups-cls-3"
            points="588.38 129.36 598.16 153.97 621.94 163.45 603.91 176.2 606.98 201.33 586.05 184.61 564.17 190.65 569.27 167.57 552.68 146.18 576.75 148.63 588.38 129.36"
          />
          <polygon
            className="startups-cls-4"
            points="166.61 320.18 177.73 341.98 201.82 339.19 184.73 363.43 189.26 389.57 167.57 382.74 146.28 401.69 149.97 373.23 132.28 358.8 156.25 348.04 166.61 320.18"
          />
        </g>

        <g id="rocket">
          <ellipse className="startups-cls-5" cx="404.02" cy="373.81" rx="57.17" ry="15.44" />
          <path className="startups-cls-6" d="M346.84,190.09v1.36a4.27,4.27,0,0,1,.23-1.36Z" />
          <path
            className="startups-cls-7"
            d="M444.44,202.36c-22.32,6-58.52,6-80.85,0-11.16-3-16.75-7-16.75-10.91v78.11c0,3.95,5.59,7.9,16.75,10.91,22.33,6,58.53,6,80.85,0,11.17-3,16.75-7,16.75-10.91V191.45C461.19,195.4,455.61,199.35,444.44,202.36Z"
          />
          <ellipse className="startups-cls-8" cx="404.02" cy="191.45" rx="57.17" ry="15.44" />
          <g className="startups-cls-9">
            <path className="startups-cls-10" d="M346.84,127.88v1.36a4.27,4.27,0,0,1,.23-1.36Z" />
          </g>
          <ellipse className="startups-cls-11" cx="404.02" cy="129.24" rx="57.17" ry="15.44" />
          <path className="startups-cls-12" d="M346.84,267.31v1.36a4.31,4.31,0,0,1,.23-1.36Z" />
          <path
            className="startups-cls-12"
            d="M444.44,279.58c-22.32,6-58.52,6-80.85,0-11.16-3-16.75-7-16.75-10.91v78.11c0,4,5.59,7.9,16.75,10.91,22.33,6,58.53,6,80.85,0,11.17-3,16.75-7,16.75-10.91V268.67C461.19,272.62,455.61,276.57,444.44,279.58Z"
          />
          <ellipse className="startups-cls-13" cx="404.02" cy="268.67" rx="57.17" ry="15.44" />
          <ellipse className="startups-cls-7" cx="404.02" cy="191.45" rx="57.17" ry="15.44" />
          <g className="startups-cls-9">
            <path className="startups-cls-14" d="M346.84,128.56v1.36a4.27,4.27,0,0,1,.23-1.36Z" />
          </g>
          <path className="startups-cls-14" d="M346.84,267.31v1.36a4.31,4.31,0,0,1,.23-1.36Z" />
          <path
            className="startups-cls-15"
            d="M444.44,279.58c-22.32,6-58.52,6-80.85,0-11.16-3-16.75-7-16.75-10.91v78.11c0,4,5.59,7.9,16.75,10.91,22.33,6,58.53,6,80.85,0,11.17-3,16.75-7,16.75-10.91V268.67C461.19,272.62,455.61,276.57,444.44,279.58Z"
          />
          <ellipse className="startups-cls-16" cx="404.02" cy="402.92" rx="57.17" ry="15.44" />
          <ellipse className="startups-cls-16" cx="405.63" cy="480.02" rx="57.17" ry="15.44" />
          <ellipse className="startups-cls-16" cx="405.63" cy="491.18" rx="57.17" ry="15.44" />
          <polygon
            className="startups-cls-17"
            points="352.39 218.72 288.5 239.25 288.5 356.57 352.39 336.04 352.39 218.72"
          />
          <polygon
            className="startups-cls-18"
            points="451.33 221.42 515.22 241.95 515.22 359.26 451.33 338.73 451.33 221.42"
          />
          <path
            className="startups-cls-19"
            d="M461.38,129.68C461.38,128.47,404.56,0,404.56,0s-57.91,128.44-57.91,129.68h0c0,4,5.6,7.93,16.8,10.95,22.4,6.05,58.73,6.05,81.13,0,11.2-3,16.8-7,16.8-10.95Z"
          />
          <polygon
            className="startups-cls-20"
            points="433 225.73 496.88 246.26 496.88 363.58 433 343.05 433 225.73"
          />
          <polygon
            className="startups-cls-21"
            points="373.95 224.12 310.07 244.65 310.07 361.96 373.95 341.43 373.95 224.12"
          />
          <path
            className="startups-cls-22"
            d="M444.44,140.15c-22.32,6-58.52,6-80.85,0-11.16-3-16.75-7-16.75-10.91v78.11c0,4,5.59,7.9,16.75,10.91,22.33,6,58.53,6,80.85,0,11.17-3,16.75-7,16.75-10.91V129.24C461.19,133.19,455.61,137.14,444.44,140.15Z"
          />
        </g>

        <g id="screen">
          <polygon
            className="startups-cls-23"
            points="842.33 615.68 530.76 532.19 530.76 276.67 842.33 360.15 842.33 615.68"
          />
          <line className="startups-cls-24" x1="555.84" y1="407.49" x2="594.92" y2="417.96" />
          <line className="startups-cls-24" x1="604.69" y1="420.58" x2="623.14" y2="425.53" />
          <line className="startups-cls-24" x1="555.84" y1="361.52" x2="594.92" y2="371.99" />
          <line className="startups-cls-24" x1="604.69" y1="374.61" x2="623.14" y2="379.55" />
          <line className="startups-cls-24" x1="632.91" y1="428.15" x2="651.36" y2="433.09" />
          <line className="startups-cls-24" x1="555.84" y1="421.61" x2="615.54" y2="437.6" />
          <line className="startups-cls-24" x1="555.84" y1="435.72" x2="572.12" y2="440.08" />
          <line className="startups-cls-24" x1="581.53" y1="442.6" x2="607.94" y2="449.68" />
          <line className="startups-cls-24" x1="617.35" y1="452.2" x2="640.15" y2="458.31" />
          <line className="startups-cls-24" x1="555.84" y1="449.83" x2="613.01" y2="465.15" />
          <line className="startups-cls-24" x1="620.61" y1="467.18" x2="647.02" y2="474.26" />
          <line className="startups-cls-24" x1="555.84" y1="465.03" x2="594.92" y2="475.5" />
          <line className="startups-cls-24" x1="604.69" y1="478.11" x2="623.14" y2="483.06" />
          <line className="startups-cls-24" x1="632.91" y1="485.68" x2="651.36" y2="490.62" />
          <line className="startups-cls-24" x1="555.84" y1="479.14" x2="615.54" y2="495.14" />
          <line className="startups-cls-24" x1="624.23" y1="497.46" x2="661.13" y2="507.35" />
          <line className="startups-cls-24" x1="555.84" y1="493.25" x2="572.12" y2="497.61" />
          <line className="startups-cls-24" x1="581.53" y1="500.13" x2="607.94" y2="507.21" />
          <line className="startups-cls-24" x1="555.84" y1="507.36" x2="613.01" y2="522.68" />
          <path
            className="startups-cls-14"
            d="M812,442.45c-5.54-6-10.82-12.34-16.64-18-12.32-11.93-26.68-17.81-42.77-18.15a53.15,53.15,0,0,0-24.44,5,2,2,0,0,1-1.69.05,26.79,26.79,0,0,1-11.91-15.06,54.85,54.85,0,0,1-1.16-5.84,30.43,30.43,0,0,0-2.11,19.51c-5.1-3.48-8.67-7.81-10.23-14h-.35c-.21,1.79-.52,3.58-.59,5.38a35,35,0,0,0,.2,5.51c.22,1.86.7,3.68,1.13,5.85-15.52-9.46-30.9-18.82-46.5-28.31,0,2,0,3.79,0,5.55a2.16,2.16,0,0,0,.73,1.19q17.79,17.19,35.6,34.34c3.8,3.66,7.59,7.34,11.41,11,.66.63,1,1.22.47,2.1-.65,1.13-1.25,2.3-1.92,3.41-1.47,2.44-3,4.83-4.46,7.29a6.07,6.07,0,0,0-.27,5.42,40.47,40.47,0,0,0,1.61,3.76,4.68,4.68,0,0,1,.32,3.43c-2.57,10.59-5.07,21.2-7.65,31.79a30.22,30.22,0,0,0,.35,14.18c1.07,4.55,3.69,7.62,7.67,9.1,4.55,1.69,9.26,1.89,14,1.76a2.09,2.09,0,0,0,1.18-.58,29,29,0,0,0,9.32-17.4,3.6,3.6,0,0,1,.85-2,24.22,24.22,0,0,0,19.73-6.94,94.34,94.34,0,0,0,1.67,53.34c13-40.69,38.71-76.72,70.52-98.18C814.7,445.41,813.35,443.92,812,442.45Z"
          />
        </g>

        <g id="wall-r">
          <path
            className="startups-cls-7"
            d="M593.47,614.27l-11,3.15c0,1.4,0,2.61,0,3.83,0,2.37-.06,4.75-.1,7.12h0v0l11.15-3.19V614.29h0Z"
          />
          <path className="startups-cls-7" d="M527.38,655.24l11-3.13v-11l-11,3.13Z" />
          <path
            className="startups-cls-7"
            d="M527.38,598.74v1.37l1.29-.37c10.16-2.9,20.33-5.82,30.49-8.69,1-.29,1.31-.64,1.3-1.67q0-15.18,0-30.38c0-.46.07-1,.11-1.42h0l0,0,0,0v0c-.33.11-.65.24-1,.33l-31.23,8.93c-.32.09-.64.15-1,.22v0h0c0,.43,0,.85,0,1.29Q527.4,583.54,527.38,598.74ZM535.7,573l16.4-4.69v16.41l-16.4,4.68Z"
          />
          <path className="startups-cls-7" d="M538.53,618.91l21.84-6.24v-10.9L538.53,608Z" />
          <path className="startups-cls-7" d="M527.47,633.1,538.34,630V619.1l-10.87,3.11Z" />
          <path
            className="startups-cls-7"
            d="M604.42,578.15l-10.95,3.13v11l-11.1,3.17v0h0a10.69,10.69,0,0,1,.1,1.15c0,2.85,0,5.71,0,8.57,0,.38,0,.76.08,1.26l10.94-3.13v11h0v0l11-3.13v-11L615.55,597V586h0v0l-11.1,3.18Z"
          />
          <path
            className="startups-cls-7"
            d="M571.52,598.53l10.82-3.09v0h0v-11l11-3.15V570.17l-11,3.14c0-.68,0-1.23,0-1.78,0-3-.05-6-.07-9h0v0l-10.81,3.1Z"
          />
          <path className="startups-cls-7" d="M615.52,553v0h0V541.85h0v0L604.4,545v0h0v11.14Z" />
          <path
            className="startups-cls-7"
            d="M560.49,623.53c0,.09.09.15.15.22l10.72-3.07v-11l-10.88,3.11C560.48,616.43,560.48,620,560.49,623.53Z"
          />
          <path className="startups-cls-7" d="M615.51,619l-11,3.14v11l11-3.13Z" />
          <path
            className="startups-cls-7"
            d="M428.71,557.87V765.38L639,709V501.53ZM626.43,627l-10.89,3.11v10.83l-22,6.3V636.32l-11.14,3.18v0h0c0,2,.09,4,.11,5.94,0,1.58,0,3.18,0,4.87l-11,3.14V642.65h0v0l-10.91,3.12c0-.45-.07-.76-.08-1.09q0-9.95,0-19.9a5.93,5.93,0,0,1,.17-1L549.43,627v32.84l-22.07,6.31V655.28l-9.53,2.64-1.36.39v-33l10.85-3.1v-11L516.5,614.3V570.17l10.84-3.1v0h0V556.19l33.17-9.48v10.84h0v0l10.85-3.11V543.59l11-3.13c0,.32.06.6.06.88q0,10.08,0,20.14a8,8,0,0,1-.11,1h0v0l11.06-3.17V548.18l11-3.13v0h0V534.17L615.52,531v10.84h0v0l10.84-3.1v11.12L615.55,553v0h0v32.92h0v0l10.84-3.1v11l-10.81,3.09v11l10.85-3.11Z"
          />
          <path
            className="startups-cls-7"
            d="M582.34,628.39v0l-10.91,3.12v11.13h0v0l10.87-3.12v0h0V628.39Z"
          />
        </g>

        <g id="wall-l">
          <path
            className="startups-cls-25"
            d="M237.66,657.29l7.85,2.1v7.35l-7.85-2.1Zm29.88,15.4,7.73,2.07v-7.39l-7.73-2.08ZM428.86,557.87V765.26L176,697.5V490.11Zm-109.79,129-11.83-3.17V673.42l-19.91-5.34v-9.93l-9.05-2.43v-8l9.84,2.63V640.08l10.33,2.77v-12.3l-22.68-6.08v10.31l-10-2.67V642l-18.7-5v-9.92l-9.94-2.66V614.13l-22.58-6v12.3l10.26,2.75v10.38l9.85,2.63v7.94l-9.11-2.44v10L206,646.38v10.3l-12.2-3.27v12.23l22.65,6.07v-9.79l9.14,2.45v9.14l9.87,2.65v8l-10.17-2.73v12.2l12.29,3.29V687L248,689.74V679.5L265,684.08v10.27l10.33,2.77v9.93l12.28,3.29V698l-9.79-2.62v-8l9.5,2.55v-9l9.16,2.45v9.8l22.55,6ZM387,562,370,557.5v34.61L387,596.65Zm26.33,55.75-16.94-4.54v34.61l16.94,4.54Zm0-48.7-16.94-4.53v34.61l16.94,4.54Z"
          />
        </g>
      </svg>
    </div>
  );
}

const animate = () => {
  const svg = document.querySelector('.startups-illustration');
  if (!svg) return;

  const timeline = anime.timeline({
    autoplay: false,
    loop: false,
  });

  const animations = [
    {
      targets: '#base',
      translateY: [20, 0],
      opacity: [0, 1],
      easing: 'easeInOutSine',
      duration: 750,
      delay: 0,
    },
    {
      targets: '#wall-l',
      translateX: [30, 0],
      translateY: [-10, 0],
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 450,
      delay: 400,
    },
    {
      targets: '#wall-r',
      translateX: [-30, 0],
      translateY: [-10, 0],
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 450,
      delay: 500,
    },
    {
      targets: '#bits > *',
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 150,
      delay: (el, i, l) => 550 + i * 50,
    },
    {
      targets: '#screen',
      translateX: [20, 0],
      translateY: [-10, 0],
      opacity: [0, 1],
      easing: 'easeOutSine',
      duration: 650,
      delay: 900,
    },
    {
      targets: '#lines > line',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeOutSine',
      duration: 600,
      delay: (el, i, l) => 950 + i * 20,
    },
    {
      targets: '#unicorn',
      opacity: [0, 1, 0, 1, 0, 1, 0, 1, 0, 1],
      easing: 'easeInExpo',
      duration: 300,
      delay: 1550,
    },
    {
      targets: '#stars > polygon',
      translateY: [10, 0],
      opacity: [0, 0.58],
      easing: 'easeOutSine',
      duration: 800,
      delay: (el, i, l) => 2550 + i * 150,
    },
    {
      targets: '#rocket',
      translateY: [300, 0],
      opacity: [0, 1],
      easing: 'easeInOutSine',
      duration: 2000,
      delay: 1500,
    },
    {
      targets: '#smoke > ellipse',
      translateY: [20, 0],
      opacity: [0, 0.56],
      easing: 'easeOutSine',
      duration: 500,
      delay: (el, i, l) => 2550 + i * 170,
    },
  ];

  animations.forEach(animation => timeline.add(animation, 0));

  svg.style.opacity = '1';
  timeline.play();
};
