import tw, { css } from 'twin.macro';

const quotationMarkStyles = css`
  ${tw`absolute text-green`}
  top: -16px;
  width: 41px;
  height: 39px;

  @media only screen and (min-width: 769px) {
    top: -14px;
    left: 38px;
    width: 65px;
    height: 62px;
  }
`;

const smallStyles = css`
  @media only screen and (min-width: 769px) {
    width: 38px;
    height: 36px;
  }
`;

const QuotationMark = ({ small }) => (
  <svg
    css={[quotationMarkStyles, small && smallStyles]}
    viewBox="0 0 65 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.1727 11.1862C19.7364 12.2175 15.9152 16.1843 13.7091 23.0864C12.6061 26.4978 12.0545 29.8695 12.0545 33.2015C12.0545 33.5982 12.0545 33.9552 12.0545 34.2726C12.1333 34.5106 12.2121 35.1056 12.2909 36.0576H25.1727V62H0V38.0806C0 26.3391 2.32424 17.2949 6.97273 10.9482C11.6212 4.60141 17.6879 0.952015 25.1727 0V11.1862ZM65 11.1862C60.6667 11.9002 57.3576 14.3596 55.0727 18.5643C52.8667 22.769 51.7636 27.6084 51.7636 33.0825C51.7636 33.5585 51.7636 34.0346 51.7636 34.5106C51.8424 34.9866 51.9606 35.5022 52.1182 36.0576H65V62H39.7091V38.0806C39.7091 28.6398 41.6788 20.2303 45.6182 12.8522C49.5576 5.39475 56.0182 1.11068 65 0V11.1862Z"
      fill="currentColor"
    />
  </svg>
);

export default QuotationMark;
