import React, { useState } from 'react';
import tw from 'twin.macro';
import { StructuredText } from 'react-datocms';
import { Section, Inner, SectionTitle } from 'components';
import Accordion, { AccordionHead } from 'components/Accordion';
import StartupScene from 'components/Illustrations/StartupScene';

const FAQs = tw.div`text-left md:w-3/5`;
const Illustration = tw.div`mt-10 w-3/5 md:(mt-0 w-2/5)`;

const FAQ = ({ heading, bodyText, faq }) => {
  const [activeTab, setActiveTab] = useState();
  const [expanded, setExpanded] = useState(10);

  const handleExpand = (q, idx) => {
    const closeItself = expanded === idx && q === activeTab;
    setActiveTab(q);
    setExpanded(closeItself ? null : idx);
  };
  return (
    <Section id="faq">
      <Inner>
        <SectionTitle>{heading}</SectionTitle>
        {bodyText && <p>{bodyText}</p>}
        <div tw="flex items-center flex-col-reverse md:flex-row">
          <FAQs>
            {faq?.map(({ question, answer }, i) => {
              const isExpanded = expanded === i;
              return (
                <React.Fragment key={i}>
                  <AccordionHead
                    setExpanded={() => handleExpand(question, i)}
                    expanded={isExpanded}
                    label={question}
                  />
                  <Accordion i={i} expanded={isExpanded}>
                    <StructuredText data={answer} />
                  </Accordion>
                </React.Fragment>
              );
            })}
          </FAQs>
          <Illustration>
            <StartupScene />
          </Illustration>
        </div>
      </Inner>
    </Section>
  );
};

export default FAQ;
