import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import { useFilterContext } from 'contexts/filter';
import { Section, Inner, SectionTitle, A } from 'components';
import { useOnScreen } from 'utils/hooks';
import { alphabetize } from 'utils';
import uniqBy from 'lodash/uniqBy';

const Grid = tw.ul`mt-6 text-xs grid grid-cols-2 md:(grid-cols-3 text-s) xl:grid-cols-5`;
const Item = tw.li`font-semibold mr-3 mb-2 md:(mr-5 mb-3)`;

const ThanksToCompanies = () => {
  const { allTalents } = useFilterContext();
  const [companiesList, setCompaniesList] = useState([]);
  const [ref, visible] = useOnScreen();

  useEffect(() => {
    if (visible) {
      const arr = allTalents.map(talent => talent.expert.company[0]);
      const uniqueList = uniqBy(arr, 'name').sort((a, b) => alphabetize(a, b, 'name'));
      setCompaniesList(uniqueList);
    }
  }, [visible]);

  return (
    <Section>
      <Inner>
        <SectionTitle ref={ref}>And thanks to...</SectionTitle>
        {visible && (
          <Grid>
            {companiesList.map(props => {
              if (!props) return null;
              return (
                <Item key={props.website}>
                  <A href={props.website} tw="hover:text-green">
                    {props.name}
                  </A>
                </Item>
              );
            })}
          </Grid>
        )}
      </Inner>
    </Section>
  );
};

export default ThanksToCompanies;
