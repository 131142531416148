import React from 'react';

const AccordionArrow = ({ ...rest }) => (
  <svg
    width="24"
    height="15"
    viewBox="0 0 24 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M22 12.5L12 2.5L2 12.5"
      stroke="currentColor"
      strokeWidth="3.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AccordionArrow;
