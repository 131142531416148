import React from 'react';
import tw from 'twin.macro';
import { Section, Inner } from 'components/index';
import FeaturedTalent from 'modules/FeaturedTalent';
import TalentPortraits from 'modules/TalentPortraits';
import FindJob from 'modules/FindJob';
import { useOnScreen } from 'utils/hooks';

const Talents = ({ listOfTalentsHeading, callToAction, alternativeCallToActionLabel, ...rest }) => {
  const [ref, visible] = useOnScreen();
  return (
    <Section id="talents">
      <div ref={ref} tw="h-1 w-full" />
      <Inner>
        <FeaturedTalent {...rest} />
        <FindJob
          callToAction={{ href: '/opportunities', label: 'Find your next job in Denmark' }}
        />
        {visible && (
          <TalentPortraits
            heading={listOfTalentsHeading}
            cta={{ callToAction, alternativeCallToActionLabel }}
          />
        )}
      </Inner>
    </Section>
  );
};

export default Talents;
