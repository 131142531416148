import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import tw, { css, styled } from 'twin.macro';
import { AnimatePresence, motion } from 'framer-motion';
import Img from 'gatsby-image';
import { useWindowSize } from 'utils/hooks';
import { Inner, Section } from 'components';
import TiltButton from 'components/TiltButton';
import Modal from 'components/Modal';
import Iframe from 'components/Iframe';
import PlayIcon from 'components/Icons/Play';
import { ArrowIcon } from 'components/Icons';
// import { TestimonialsDecorations } from 'components/Decorations';
import testimonialDecoration from 'assets/testimonial-decoration.svg';
import testimonialDecorationMobile from 'assets/testimonial-decoration-mobile.svg';

const sectionTitleStyles = css`
  @media only screen and (min-width: 768px) {
    margin-top: -115px;
  }
  p {
    ${tw`w-104 font-bold m-0 leading-tight text-xl sm:text-4xl`}
    @media only screen and (max-width: 768px) {
      ${tw`mt-0 w-2/3`}
    }
  }
`;
const Container = tw.div`flex w-full justify-between relative flex-col xl:(h-140 flex-row)`;
const Spotlight = tw.div`w-full xl:w-3/4`;
const Playlist = tw.div`h-full xl:w-1/3`;
const PlaylistInner = tw.ul`px-5 border border-solid border-white h-full py-5 mt-6 xl:(ml-8 mt-0 py-8)`;
const activePlaylistItemStyles = css`
  span {
    ${tw`translate-x-2`}
  }
  svg {
    ${tw`scale-100`}
  }
`;
const PlaylistItem = styled.button(({ active }) => [
  css`
    span {
      ${tw`inline-block transition-transform duration-500 transform -translate-x-5`}
    }
    svg {
      ${tw`origin-left transition-transform duration-500 transform scale-0`}
    }
    &:hover {
      ${activePlaylistItemStyles}
    }
  `,
  tw`block cursor-pointer text-base py-1`,
  active && [tw`text-red`, activePlaylistItemStyles],
]);
const Controls = tw.div`relative flex justify-between items-center mt-10 flex-col lg:block`;
const Name = tw.h2`font-bold lg:text-4xl`;
const imageStyles = tw`w-full h-full`;
const Banner = tw.div`absolute w-full bottom-0 bg-blue p-5 flex justify-between items-center`;
const WatchVideo = tw.div`bg-white text-red flex items-center text-xs font-semibold rounded-full p-3 sm:(px-5 py-3)`;

const RoleAtCompany = ({ jobPosition, companyName }) => (
  <>
    <span>{jobPosition}</span>
    {companyName && (
      <>
        <span tw="text-white-50 mx-2">at</span>
        {companyName}
      </>
    )}
  </>
);

const TestimonialsPlaylist = ({ headingNode, bodyText, videos }) => {
  const { width } = useWindowSize();
  const [openModal, setOpenModal] = useState(false);
  const [videoIndex, setVideoIndex] = useState(0);

  const { name, jobPosition, company } = videos[videoIndex]?.expert;

  const [[page, direction], setPage] = useState([0, 0]);

  const paginate = newDirection => {
    setPage([page + newDirection, newDirection]);
  };

  return (
    <>
      <Section id="testimonials">
        <Inner>
          {width && (
            <ReactSVG
              src={width < 768 ? testimonialDecorationMobile : testimonialDecoration}
              tw="text-center"
            />
          )}
          <h2
            css={sectionTitleStyles}
            dangerouslySetInnerHTML={{ __html: headingNode.childMarkdownRemark.html }}
          />
          <p tw="sm:w-1/3 mt-4 mb-6">{bodyText}</p>
          <Container>
            <Spotlight>
              <div onClick={() => setOpenModal(true)} tw="relative h-96 sm:h-140">
                <AnimatePresence>
                  <motion.div
                    key={videos[videoIndex].videoPlaceholder.filename}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    tw="absolute inset-0 cursor-pointer"
                  >
                    <Img
                      fluid={videos[videoIndex].videoPlaceholder.fluid}
                      css={imageStyles}
                      imgStyle={{ opacity: 1 }}
                    />
                    <Banner>
                      <div tw="w-4/5">
                        <div>
                          <h2 tw="text-l">
                            {name}, {jobPosition}
                          </h2>
                        </div>
                        <p tw="w-11/12 opacity-80 text-xs sm:text-base">
                          {videos[videoIndex].caption}
                        </p>
                      </div>
                      <WatchVideo>
                        <PlayIcon tw="sm:mr-2" />
                        <span tw="hidden sm:block">Watch Video</span>
                      </WatchVideo>
                    </Banner>
                  </motion.div>
                </AnimatePresence>
              </div>
            </Spotlight>
            <Playlist>
              <PlaylistInner>
                <div tw="flex justify-between items-center border-0 border-b-2 border-dashed pb-5 mb-5">
                  <h2>Playlist</h2>
                  <p tw="font-semibold">{videos.length} videos</p>
                </div>
                {videos?.map((item, i) => (
                  <PlaylistItem onClick={() => setVideoIndex(i)} active={i === videoIndex} key={i}>
                    <ArrowIcon />
                    <span>{item.expert.name}</span>
                  </PlaylistItem>
                ))}
              </PlaylistInner>
            </Playlist>
          </Container>
        </Inner>
      </Section>
      <Modal isOpen={openModal} setOpenModal={setOpenModal}>
        <div tw="mx-5 2xl:mx-0">
          <Iframe
            url={videos[videoIndex].videoEmbedUrl}
            paginate={paginate}
            direction={direction}
            videoTitle={name}
          />
          <Controls>
            <div>
              <Name>{name}</Name>
              <RoleAtCompany jobPosition={jobPosition} companyName={company[videoIndex]?.name} />
            </div>
            <div tw="flex w-full justify-between relative mt-5 lg:(absolute top-0)">
              <TiltButton
                label="Previous video"
                accent="#5ec2bb"
                onClick={() => {
                  paginate(-1);
                  setVideoIndex(v => v - 1);
                }}
                reverse
                hide={videoIndex === 0}
              />
              <TiltButton
                label="Next video"
                accent="#5ec2bb"
                onClick={() => {
                  paginate(1);
                  setVideoIndex(v => v + 1);
                }}
                hide={videoIndex === videos.length - 1}
              />
            </div>
          </Controls>
        </div>
      </Modal>
    </>
  );
};

export default TestimonialsPlaylist;
