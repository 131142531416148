import React from 'react';
import tw from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { SectionTitle } from './index';

const Blue = tw.span`text-blue-talent`;

const HeadingWithData = () => {
  const {
    allDatoCmsTechTalent,
    datoCmsTalentPortrait: { featuredTalent },
  } = useStaticQuery(graphql`
    {
      allDatoCmsTechTalent {
        edges {
          node {
            title
            highlightedQuote
            expert {
              yearStartedInTech
            }
          }
        }
        totalCount
      }
      datoCmsTalentPortrait {
        featuredTalent {
          expert {
            name
          }
        }
      }
    }
  `);

  const { edges, totalCount } = allDatoCmsTechTalent;
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  const d = new Date().getFullYear();
  const years = edges?.map(x => {
    const yearStartedInTech = x?.node?.expert?.yearStartedInTech;
    if (yearStartedInTech) return d - yearStartedInTech;
    else return 0;
  });
  const totalYearsInTech = years.reduce(reducer);

  const { name } = featuredTalent.expert;

  return (
    <>
      <SectionTitle>Don't take our word for it</SectionTitle>
      <p tw="text-s mt-2 sm:(text-l pr-12) xl:w-1/2">
        Listen to <Blue>{name.split(' ')[0]}</Blue>, and the <Blue>{totalCount}</Blue> other
        talented people we interviewed. Living and working in Denmark from abroad, with over{' '}
        <Blue>{totalYearsInTech}</Blue> years of combined experience.
      </p>
    </>
  );
};

export default HeadingWithData;
