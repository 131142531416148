import React from 'react';
import tw, { css } from 'twin.macro';
import Img from 'gatsby-image';
// import { FeaturedDecorations } from '../components/IconDecorations';
import { A } from 'components';
import QuotationMark from '../components/Icons/QuotationMark';
import HeadingWithData from '../components/HeadingWithData';

const quoteContainerStyles = css`
  margin-left: -20px;
  ${tw`bg-blue px-5 pb-10 text-l sm:(absolute z-10 left-0 w-1/2 text-lg mt-8 ml-0 pb-2) md:(text-2xl px-8 pb-3) xl:(mt-20 text-3xl)`}
  blockquote {
    ${tw`font-semibold sm:(mb-4 font-bold leading-tight) mt-8 md:mt-16`}
  }
  span {
    ${tw`text-lg block`}
  }
`;

const imageCardStyles = css`
  margin-top: -30px;
  margin-right: -20px;
  ${tw`sm:w-3/5`}
`;

const Container = tw.div`relative flex flex-col sm:flex-row justify-end my-10`;

const QuoteCard = ({ quote }) => (
  <div css={quoteContainerStyles}>
    <QuotationMark />
    <blockquote>{quote}</blockquote>
  </div>
);

const ImageCard = ({ name, jobPosition, homeCountry, techTalentImage }) => (
  <div css={imageCardStyles}>
    <Img fluid={techTalentImage?.fluid} alt={name} />
    <div tw="w-full bottom-0 bg-blue py-4 px-5 flex items-center justify-between">
      <div>
        <h3 tw="font-semibold">{name}</h3>
        <p
          css={[
            tw`text-green text-base`,
            css`
              line-height: 0.9;
            `,
          ]}
        >
          {jobPosition}
        </p>
      </div>
      <img
        tw="h-9 border border-white-25"
        src={homeCountry.flag.url}
        alt={`${homeCountry.country} flag`}
      />
    </div>
  </div>
);

const FeaturedTalent = ({ featuredTalent: { expert, highlightedQuote, slug } }) => (
  <>
    <HeadingWithData />
    <A href={`https://blog.digitalhubdenmark.dk/${slug}`}>
      <Container>
        {/* <FeaturedDecorations /> */}
        <QuoteCard quote={highlightedQuote} author={expert.name} />
        <ImageCard {...expert} />
      </Container>
    </A>
  </>
);

export default FeaturedTalent;
