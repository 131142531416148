import React, { useEffect, useRef } from 'react';
import GlueFrame from 'glueframe';
import tw, { css, styled } from 'twin.macro';
import { Section, Inner, PageTitle } from 'components';
import { PaperPlane } from 'components/Icons';
import { ThreeBubbles } from 'components/IconDecorations';
import TiltButton from 'components/TiltButton';

const Gradient = styled.div(({ hex }) => [
  css`
    :after {
      content: "";
      z-index: -10;
      ${tw`absolute inset-0 h-full`}
      background-image: linear-gradient(0deg, ${hex} -15%, ${hex}00 100%);
      // background: linear-gradient(180deg, #5EC2BB -9.33%, #0A1236 100%);
    }
  `,
]);

const bubblesBottomStyles = css`
  // top: 380px;
  transform: scaleY(-1);
  ${tw`absolute hidden z-10 sm:block`}
  left: -50px;
  bottom: -50px;
`;

const planeTopRightStyles = css`
  top: -30px;
  right: -10px;
  transform: rotate(-20deg);
  ${tw`absolute z-10`}

  @media only screen and (max-width: 768px) {
    top: -30px;
    right: -20px;
    transform: scale(0.65) rotate(-20deg);
  }
`;

const VideoContainer = styled.div`
  ${tw`relative overflow-hidden w-full`};
  padding-bottom: 37.5%;
`;

const iframeStyles = css`
  ${tw`absolute inset-0 w-full h-full`}
`;

const Hero = ({
  heading,
  bodyText,
  callToAction,
  videoEmbed,
  alternativeCallToActionLabel,
  gradientColor: { hex },
}) => {
  const ref = useRef();

  useEffect(() => {
    const videoTitle = 'talented in denmark introduction';
    if (typeof window !== 'undefined') {
      const Iframe = new GlueFrame(ref.current, 'Player');
      Iframe.bind('player:video:playing', () => {
        window.dataLayer = window.dataLayer || [];

        const firstPlay = !window.dataLayer.some(({ event }) => event === 'video_play');
        const sameVideo = window.dataLayer.some(item => item.video_title === videoTitle);

        if (firstPlay || !sameVideo) {
          window.dataLayer.push({
            event: 'video_play',
            video_title: videoTitle,
          });
        }
      });
    }
  }, []);

  return (
    <Section tw="h-full" show>
      <Inner tw="flex flex-col relative mt-28">
        <PageTitle tw="mb-0 pb-0 text-center text-xl sm:text-3xl md:text-5xl xl:pr-20">
          {heading}
        </PageTitle>
        <div tw="text-center">
          <TiltButton
            label={alternativeCallToActionLabel || callToAction.label}
            to={callToAction.href}
            tw="mt-6 md:mt-10"
            background="yellow"
          />
        </div>
        <div tw="relative max-w-4xl w-full mx-auto mt-6 md:(mt-10 mb-6)">
          <PaperPlane css={planeTopRightStyles} />
          <ThreeBubbles css={bubblesBottomStyles} />
          {videoEmbed && (
            <VideoContainer>
              <iframe
                ref={ref}
                id="iframe"
                src={videoEmbed}
                frameBorder="0"
                border="0"
                css={iframeStyles}
                scrolling="no"
                mozallowfullscreen="1"
                webkitallowfullscreen="1"
                allowFullScreen="1"
                allow="autoplay"
                title="talented in denmark introduction"
              />
            </VideoContainer>
          )}
        </div>
        <p tw="2xl:w-2/3 3xl:w-3/5 text-center mx-auto xl:pr-6">{bodyText}</p>
      </Inner>
      <Gradient hex={hex || '#0a1236'} />
    </Section>
  );
};

export default Hero;
