import React, { useEffect, useState } from 'react';
import tw, { css } from 'twin.macro';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useFilterContext } from 'contexts/filter';
import { SectionTitle, A } from 'components';
import QuotationMark from 'components/Icons/QuotationMark';
import Filter from 'components/Filter';
import TiltButton from 'components/TiltButton';
import FindJob from 'modules/FindJob';
// import { useOnScreen } from 'utils/hooks';

const AnimateIn = css`
  li {
    animation: 1s animate forwards;
  }
  @keyframes animate {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Grid = tw.ul`mt-6 sm:mt-3 flex flex-wrap flex-row justify-between md:(gap-6 grid grid-cols-3)`;
const cardStyles = css`
  ${tw`mb-4 w-full sm:w-49/100 md:(w-full mb-0) bg-blue relative opacity-0 h-90 sm:h-72 xl:h-90 2xl:h-96`}
  .gatsby-image-wrapper {
    transition: opacity 500ms;
  }
  &:hover .card-hover {
    ${tw`hidden lg:(block opacity-100)`}
  }
  &:hover .gatsby-image-wrapper {
    ${tw`lg:opacity-0`}
  }
  &:hover svg {
    transform: translateY(0);
  }
  .gatsby-image-wrapper {
    ${tw`w-full`}
  }
`;

const Caption = tw.div`flex items-center justify-between px-4 py-3 sm:p-6 absolute w-full bottom-0 bg-blue h-28`;

const hoverStyles = css`
  ${tw`p-5 absolute top-0 font-semibold`}
  padding-top: 40px;
  font-size: 1.5rem;
  line-height: 1.4;
  opacity: 0;
  transition: opacity 500ms 100ms;

  svg {
    transition: transform 500ms 100ms;
    transform: translateY(10px);
    left: 26px;
  }
`;

const flagStyles = css`
  width: 50px;
  height: auto;
  border: 1px solid #ffffff30;
`;

const TalentPortraits = ({ heading, cta }) => {
  const { talents } = useFilterContext();
  const [paginatedPosts, setPaginatedPosts] = useState();
  const [pageCount, setPageCount] = useState(0);

  const paginatePosts = () => {
    const n = 12;
    const arr = [];
    let i = 0;
    while (i < talents?.length) arr.push(talents.slice(i, (i += n)));
    setPaginatedPosts(arr);
  };

  useEffect(() => {
    paginatePosts();
  }, [talents]);

  return (
    <>
      <SectionTitle>{heading}</SectionTitle>
      <Filter showAll />
      {paginatedPosts?.map((_, i) => {
        if (i > pageCount) return null;
        return (
          <Grid css={AnimateIn} key={i}>
            {paginatedPosts[i].map(
              (
                {
                  slug,
                  highlightedQuote,
                  expert: { featuredMedia, jobPosition, homeCountry, name },
                },
                i
              ) => (
                <li css={cardStyles} style={{ animationDelay: `${i * 75}ms` }} key={slug}>
                  <A href={`https://blog.digitalhubdenmark.dk/${slug}`} key={slug}>
                    <div tw="overflow-hidden h-full">
                      <GatsbyImage image={featuredMedia.gatsbyImageData} alt={name} />
                    </div>
                    <div className="card-hover" css={hoverStyles} tw="font-semibold">
                      <QuotationMark small />
                      <blockquote>{highlightedQuote}</blockquote>
                    </div>
                    <Caption>
                      <div>
                        <h3 tw="text-xl font-semibold leading-none">{name}</h3>
                        <span tw="text-green inline-block leading-tight mt-1">{jobPosition}</span>
                      </div>
                      {homeCountry?.flag && (
                        <img
                          src={homeCountry.flag?.fixed.src}
                          alt={`${name} portrait photo`}
                          css={flagStyles}
                          loading="lazy"
                        />
                      )}
                    </Caption>
                  </A>
                </li>
              )
            )}
            <FindJob {...cta} />
          </Grid>
        );
      })}
      {paginatedPosts?.length > 0 && pageCount + 1 < paginatedPosts?.length && (
        <div tw="text-center py-10 bg-white-10 border border-solid border-white-25">
          {pageCount + 1 < paginatedPosts?.length && (
            <TiltButton
              label="Load more"
              onClick={() => setPageCount(val => val + 1)}
              arrowDirection={css`
                transform: rotate(90deg);
              `}
            />
          )}
        </div>
      )}
    </>
  );
};

export default TalentPortraits;
