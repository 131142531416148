import React from 'react';
import tw from 'twin.macro';
import { SectionTitle } from 'components/index';
import Filter from 'components/Filter';
import TiltButton from 'components/TiltButton';

const FindJob = ({ callToAction, alternativeCallToActionLabel, ...rest }) => (
  <div tw="col-span-3 pt-6 pb-8 flex flex-col items-center my-3" {...rest}>
    <SectionTitle>Find your next job opportunity in Denmark</SectionTitle>
    <Filter withIcons inFunnel />
    <TiltButton
      label={alternativeCallToActionLabel || callToAction?.label}
      to={callToAction?.href}
      accent="white"
      tw="mt-4"
      background="raspberry"
    />
  </div>
);

export default FindJob;
