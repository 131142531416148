import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Hero from 'modules/Hero';
import StickyCTA from 'modules/StickyCTA';
import Talents from 'modules/Talents';
import NewsletterSlim from 'modules/NewsletterSlim';
import TestimonialsPlaylist from 'modules/TestimonialsPlaylist';
import DenmarkInNumbers from 'modules/DenmarkInNumbers';
import ThreeColumnCTA from 'modules/ThreeColumnCTA';
import FAQ from 'modules/FAQ';
import ThanksToCompanies from 'modules/ThanksToCompanies';
// import EcospheresTabs from 'modules/EcospheresTabs';
import Layout from 'components/Layout';

const IndexPage = () => {
  const { datoCmsPage } = useStaticQuery(indexQuery);
  if (!datoCmsPage) return null;
  const { blocks } = datoCmsPage;

  const components = blocks.map(block => {
    if (block?.model) {
      const { apiKey } = block.model;

      switch (apiKey) {
        case 'hero':
          return <Hero {...block} key={apiKey} />;
        case 'sticky_call_to_action':
          return <StickyCTA {...block} key={apiKey} />;
        case 'talent_portrait':
          return <Talents {...block} key={apiKey} />;
        case 'talent_testimonials_playlist':
          return <TestimonialsPlaylist {...block} key={apiKey} />;
        case 'denmark_in_number':
          return <DenmarkInNumbers noJobs {...block} key={apiKey} />;
        case 'three_column_cta':
          return <ThreeColumnCTA {...block} key={apiKey} />;
        case 'newsletter_slim':
          return <NewsletterSlim key={apiKey} />;
        case 'faq_accordion':
          return <FAQ {...block} key={apiKey} />;
        default:
          break;
      }
    }
  });

  components.push(<ThanksToCompanies key="thanks" />);

  return <Layout>{components}</Layout>;
};

const indexQuery = graphql`
  {
    datoCmsPage(title: { eq: "Talented in Denmark" }) {
      blocks {
        ... on DatoCmsHero {
          model {
            apiKey
          }
          heading
          bodyText
          callToAction {
            label
            href
          }
          alternativeCallToActionLabel
          videoEmbed
          gradientColor {
            hex
          }
        }
        ... on DatoCmsStickyCallToAction {
          model {
            apiKey
          }
          text
          callToAction {
            label
            href
          }
          alternativeCallToActionLabel
        }
        ... on DatoCmsTalentPortrait {
          model {
            apiKey
          }
          featuredTalentHeading
          listOfTalentsHeading
          featuredTalent {
            slug
            highlightedQuote
            expert {
              name
              jobPosition
              homeCountry {
                flag {
                  url
                }
              }
              techTalentImage {
                fluid {
                  ...GatsbyDatoCmsFluid
                }
              }
            }
          }
          callToAction {
            label
            href
          }
          alternativeCallToActionLabel
        }
        ... on DatoCmsTalentTestimonialsPlaylist {
          model {
            apiKey
          }
          headingNode {
            childMarkdownRemark {
              html
            }
          }
          bodyText
          videos {
            videoEmbedUrl
            expert {
              name
              jobPosition
              company {
                name
              }
            }
            caption
            videoPlaceholder {
              filename
              fluid(
                forceBlurhash: true
                maxWidth: 800
                imgixParams: { maxW: 800, fm: "webp", auto: "compress" }
              ) {
                ...GatsbyDatoCmsFluid
              }
              blurhash
              colors {
                hex
              }
            }
          }
        }
        ... on DatoCmsDenmarkInNumber {
          model {
            apiKey
          }
          heading
          bodyText
          facts {
            number
            label
            icon {
              url
            }
          }
        }
        ... on DatoCmsThreeColumnCta {
          model {
            apiKey
          }
          heading
          headingColumn1
          bodyTextColumn1
          callToActionColumn1 {
            label
            href
          }
          headingColumn2
          bodyTextColumn2
          callToActionColumn2 {
            label
            href
          }
          headingColumn3
          bodyTextColumn3
          callToActionColumn3 {
            label
            href
          }
        }
        ... on DatoCmsNewsletterSlim {
          model {
            apiKey
          }
          callToActionLabel
        }
        ... on DatoCmsInPartnershipWith {
          model {
            apiKey
          }
          heading
        }
        ... on DatoCmsFaqAccordion {
          model {
            apiKey
          }
          heading
          faq {
            question
            answer {
              value
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
