const PlayIcon = ({ ...rest }) => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M17.3017 7.74607C18.7809 8.44757 18.7809 10.5524 17.3017 11.2539L2.77298 18.1442C1.48504 18.755 1.04118e-06 17.8157 1.10348e-06 16.3903L1.70585e-06 2.60973C1.76816e-06 1.18429 1.48505 0.244992 2.77298 0.855799L17.3017 7.74607Z"
      fill="#EE105A"
    />
  </svg>
);

export default PlayIcon;
