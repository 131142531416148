import { motion, AnimatePresence } from 'framer-motion';
import tw from 'twin.macro';
import { Inner } from 'components';

const modalStyles = tw`fixed z-999 inset-0 bg-blue-dark bg-opacity-95 overflow-y-scroll`;
const modalContentStyles = tw`text-center py-10`;
const Close = tw.button`text-green text-2xl mb-8 font-semibold`;

const Modal = ({ children, isOpen, setOpenModal }) => {
  const handleCloseModal = e => {
    if (e.target.id === 'modal') setOpenModal(false);
  };
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          id="modal"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          css={modalStyles}
          onClick={e => handleCloseModal(e)}
        >
          <Inner css={modalContentStyles}>
            <Close onClick={() => setOpenModal(false)}>Close</Close>
            {children}
          </Inner>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
